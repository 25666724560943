figure.pay_merchant_logo {
    margin: 0 15px 0 0;
}

h4.merchant_title {
    font-size: 14px;
    margin-bottom: 0;
}

.copiable {
    cursor: pointer;
}

.ui.popup {
    z-index: 25000;
}

.btn.btn-sm {
    padding: 0.5em 1em;
    width: unset;
}

.wallet-container {
    padding: 0.5em 0;
    display: none;
}

@media only screen and (max-width: 768px) {
    .wallet-container {
        display: block;
    }
}

.display-label{
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}

.ui.small.image, .ui.small.images .image, .ui.small.images img, .ui.small.images svg{
    width: 120px;
}